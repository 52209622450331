.footerHelper {
  width: 100%;
  background-color: black;
}

.footer {
  color: white;
  margin: auto;
  width: 80%;
  font-size: 14px;
  padding: 20px 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: flex-start;
  align-content: flex-start;
}

.elementFooter {
  width: 250px;
}

.titleFooter {
  font-weight: bold;
  font-size: 16px;
}

.iconsFooter {
  padding: 10px 20px;
  background-color: white;
  border-radius: 50px;
}

.iconsFooter a {
  margin: 5px;
}

.iconFooter {
  width: 50px;
  height: 50px;
}

.footerLogo {
  width: 200px;
}