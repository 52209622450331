.containerForMap {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  flex-wrap: wrap;
  font-family: 'Exo', sans-serif;
}

@media (max-width: 830px) {
  .containerForMap {
    flex-direction: column-reverse;
  }
}

.mapContainer {
  width: 60%;
  min-width: 320px;
  margin: 20px auto;
  height: 410px;
}

.contactForm {
  width: 20%;
  min-width: 300px;
  height: 400px;
  margin: 20px auto;
  border: 3px solid #005EDD;
  border-radius: 20px;
  padding: 10px 10px 0 10px;
}

.formContainer {
  width: 95%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sendFormButton {
  width: 50%;
  max-width: 30rem;
  margin: 20px auto;
  padding: 0.5rem;
  font: inherit;
  border: 0;
  background: #005EDD;
  color: white;
  cursor: pointer;
  font-size: 1.15rem;
  border-radius: 30px;
  transition: 0.2s;
}

.formSendInput {
  margin: 10px auto;
  padding: 10px;
  font: inherit;
  border: 2px solid #005EDD;
  background: transparent;
  color: black;
  cursor: pointer;
  font-size: 1.15rem;
  border-radius: 30px;
  text-align: center;
}
  
.sendFormButton:hover,
.sendFormButton:active {
  background: #005EDD;
}
  
.sendFormButton:focus {
  outline: none;
}
  
.sendFormButton:disabled,
.sendFormButton:focus:disabled,
.sendFormButton:hover:disabled,
.sendFormButton:active:disabled {
  background: #ccc;
  border-color: #ccc;
  color: #666666;
  cursor: not-allowed;
}

.selectContainer {
  text-align: center;
  padding: 10px;
  cursor: pointer;
  margin: 20px auto;
  color: #666666;
  border: 2px solid #005EDD;
  background: transparent;
  font-size: 1.15rem;
  font-family: 'Exo', sans-serif;
  border-radius: 30px;
}

.login {
  box-sizing: border-box;
  width: 90%;
  max-width: 30rem;
  margin: 1rem auto;
  padding: 2rem;
}

.control {
  display: flex;
  align-items: center;
  justify-content: center;
}

.control.invalid input {
  border-color: #ee9e9e;
  background: #ee9e9e;
}


@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css");

/* ---------------Animation---------------- */

.slit-in-vertical {
	-webkit-animation: slit-in-vertical 0.45s ease-out both;
	        animation: slit-in-vertical 0.45s ease-out both;
}

@-webkit-keyframes slit-in-vertical {
  0% {
    -webkit-transform: translateZ(-800px) rotateY(90deg);
            transform: translateZ(-800px) rotateY(90deg);
    opacity: 0;
  }
  54% {
    -webkit-transform: translateZ(-160px) rotateY(87deg);
            transform: translateZ(-160px) rotateY(87deg);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateZ(0) rotateY(0);
            transform: translateZ(0) rotateY(0);
  }
}
@keyframes slit-in-vertical {
  0% {
    -webkit-transform: translateZ(-800px) rotateY(90deg);
            transform: translateZ(-800px) rotateY(90deg);
    opacity: 0;
  }
  54% {
    -webkit-transform: translateZ(-160px) rotateY(87deg);
            transform: translateZ(-160px) rotateY(87deg);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateZ(0) rotateY(0);
            transform: translateZ(0) rotateY(0);
  }
}

/*---------------#region Alert--------------- */

#dialogoverlay{
  display: none;
  opacity: .8;
  position: fixed;
  top: 0px;
  left: 0px;
  background: rgba(0, 0, 0, 0.4);
  width: 100%;
  z-index: 10;
}

#dialogbox{
  display: none;
  position: fixed;
  background: rgb(42, 45, 209);
  border-radius:7px; 
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.575);
  transition: 0.3s;
  width: 40%;
  z-index: 10;
  top:50px;
  left: 0;
  right: 0;
  margin: auto;
}

#dialogbox:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.911);
}

.container {
  padding: 2px 16px;
}

.pure-material-button-contained {
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  border: none;
  border-radius: 4px;
  padding: 0 16px;
  min-width: 64px;
  height: 36px;
  vertical-align: middle;
  text-align: center;
  text-overflow: ellipsis;
  text-transform: uppercase;
  color: rgb(var(--pure-material-onprimary-rgb, 255, 255, 255));
  background-color: rgb(29, 47, 207);
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  font-family: var(--pure-material-font, "Roboto", "Segoe UI", BlinkMacSystemFont, system-ui, -apple-system);
  font-size: 14px;
  font-weight: 500;
  line-height: 36px;
  overflow: hidden;
  outline: none;
  cursor: pointer;
  transition: box-shadow 0.2s;
}

.pure-material-button-contained::-moz-focus-inner {
  border: none;
}

/* ---------------Overlay--------------- */

.pure-material-button-contained::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgb(var(--pure-material-onprimary-rgb, 255, 255, 255));
  opacity: 0;
  transition: opacity 0.2s;
}

/* Ripple */
.pure-material-button-contained::after {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  border-radius: 50%;
  padding: 50%;
  width: 32px; /* Safari */
  height: 32px; /* Safari */
  background-color: rgb(var(--pure-material-onprimary-rgb, 255, 255, 255));
  opacity: 0;
  transform: translate(-50%, -50%) scale(1);
  transition: opacity 1s, transform 0.5s;
}

/* Hover, Focus */
.pure-material-button-contained:hover,
.pure-material-button-contained:focus {
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
}

.pure-material-button-contained:hover::before {
  opacity: 0.08;
}

.pure-material-button-contained:focus::before {
  opacity: 0.24;
}

.pure-material-button-contained:hover:focus::before {
  opacity: 0.3;
}

/* Active */
.pure-material-button-contained:active {
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.pure-material-button-contained:active::after {
  opacity: 0.32;
  transform: translate(-50%, -50%) scale(0);
  transition: transform 0s;
}

/* Disabled */
.pure-material-button-contained:disabled {
  color: rgba(var(--pure-material-onsurface-rgb, 0, 0, 0), 0.38);
  background-color: rgb(29, 47, 207);
  box-shadow: none;
  cursor: initial;
}

.pure-material-button-contained:disabled::before {
  opacity: 0;
}

.pure-material-button-contained:disabled::after {
  opacity: 0;
}

#dialogbox > div{ 
  background:#FFF; 
  margin:8px; 
}

#dialogbox > div > #dialogboxhead{ 
  background: rgb(29, 47, 207); 
  font-size:19px; 
  padding:10px; 
  color:rgb(255, 255, 255); 
  font-family: Verdana, Geneva, Tahoma, sans-serif ;
}

#dialogbox > div > #dialogboxbody{ 
  background:rgb(29, 47, 207); 
  padding:20px; 
  color:#FFF; 
  font-family: Verdana, Geneva, Tahoma, sans-serif ;
}

#dialogbox > div > #dialogboxfoot{ 
  background: rgb(29, 47, 207); 
  padding:10px; 
  text-align:right; 
}
/*#endregion Alert*/