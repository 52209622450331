.App {
  text-align: center;
  font-family: 'Exo', sans-serif;
}

section {
  padding-top: 50px;
}

#home {
  padding-top: 0;
}
