.profiles {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin: 20px 80px;
  font-family: 'Exo', sans-serif;
}

.titleTeam {
  color: black;
  font-family: 'Exo', sans-serif;
}

.profile .profileImage {
  height: 200px;
  width: 200px;
  border-radius: 50%;
  transition: 0.3s;
}

.profile:hover .profileImage {
  height: 230px;
  width: 230px;
}

.userName {
  margin-top: 15px;
  transition: 0.3s;
}

.profile:hover .userName {
  font-size: 30px;
}

@media (max-width: 700px) {
  .titleTeam {
    font-size: 70px;
  }

  #downTitleText {
    margin-bottom: 15%;
  }
}