.top {
  height: 100vh;
  /* background: url('../../assets/images/Offer/background.jpg') no-repeat center; */
  background: url('../../assets/images/new_10_06_2023/BOLID-8...jpg') no-repeat center;
  background-size: cover;
  text-align: center;
  color: rgb(245,245,245);
  overflow: auto;
}

.headerBackground {
  height: 100vh;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  position: absolute;
  top: 0;
}

.contentHeader {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  flex-direction: column;
  align-content: center;
}

.titleHeader {
  margin-top: 200px;
  font-size: 3.5em;
  font-weight: normal;
  text-shadow: 0 0 3px rgb(10,10,10)
}

.top h1 {
  font-size: 3.5em;
  font-weight: normal;
  text-shadow: 0 0 3px rgb(10,10,10);
}

.top h1.dateStartCourse {
  margin:0 0 50px 0;
}

.buttonAddToCourseMenu {
  color: white;
  font-size: 18px;
  width: 300px;
  padding: 10px;
  background: #005EDD;
  border: 1px solid #005EDD;
  border-radius: 30px;
  margin: 0 auto;
}

.buttonAddToCourseMenu:hover {
  cursor: pointer;
}

.orgMeetingMobile {
  display: none;
}

.orgMeetingWeb {
  display: block;
}

@media (max-width: 700px) {
  .top h1 {
    font-size: 40px;
    margin: 200px 10px 0 10px;
  }

  .linkToAddCourseHeader {
    margin: 10px;
  }

  .top h1.dateStartCourse {
    margin-top: 10px;
  }

  .subtitle {
    margin-bottom: 10px;
  }

  .timeOfOrganization {
    margin: 0;
  }

  .orgMeetingMobile {
    display: block;
  }

  .orgMeetingWeb {
    display: none;
  }
}