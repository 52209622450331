.passingRate {
  text-align: center;
  background-image: url('../../assets/images/new_10_06_2023/BOLID-8...jpg');
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  color: white;
  padding-top: 0;
}

.passingRate p.text {
  font-family: 'Exo', sans-serif;
  font-size: 45px;
  text-transform: uppercase;
  margin-bottom: 25px;
  color:rgb(101, 173, 233)
}

.passingRate p {
  font-family: 'Exo', sans-serif;
}

.passingRate p.author {
  margin-top: 12px;
}

.passingRate .bg {
  padding: 30px 10px;
  background-color: rgba(0, 0, 0, 0.2);
}

#textCounter {
  font-size: 70px;
}

#linkToRanking {
  color:rgb(112, 132, 221);
  text-decoration: none;
}

#titleRating {
  padding-top: 0;
}