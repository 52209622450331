.offerSection {
  padding-top: 20px;
  color: white;
}

.title {
  margin: 20px;
  font-family: 'Exo', sans-serif;
  text-transform: uppercase;
}

.offerBackground {
  position: absolute;
  width: 100%;
  height: 250px;
  background: url('../../assets/images/Offer/afterChange.png') no-repeat;
  background-position: center;
  background-size: cover;
  z-index: -2;
}

.colorChanger {
  position: absolute;
  width: 100%;
  height: 250px;
  background-color: rgba(0,0,0,0.6);
  z-index: -1;
}

.offerContainer {
  margin: 0 10%;
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
  justify-content: space-around;
}

.item {
  width: 250px;
  height: 450px;
  padding: 20px;
  background: #005EDD;
  border: 1px solid #005EDD;
  border-radius: 20px;
  margin-bottom: 10px;
}

.itemTitle {
  text-align: center;
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 10px;
  margin-top: 10px;
}

.item ul {
  text-align: center;
  height: 250px;
  padding: 10px;
  border: 1px solid #ffffff;
  list-style: none;
  border-radius:10px;
}

.item li {
  margin: 10px 0;
}

.item li.bigPlus {
  font-size: 30px;
  margin: 0;
  padding: 0;
  line-height: 15px;
}

.buttonAddToCourse {
  width: 60%;
  margin: 10px auto;
  padding: 10px;
  background-color:white;
  color: #005EDD;
  border:none;
  border-radius: 15px;
  font-size: 22px;
  cursor: pointer;
}

.price {
  margin: 0;
  font-size: 22px;
}

.flexForVoucher {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

@media (max-width: 700px) {
  .title {
    font-size: 40px;
    padding-top: 15px;
  }

  .offerBackground {
    background-position-x: 0;
  }
}