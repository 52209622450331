.helperDarkBox {
  background-color: rgba(0, 0, 0, 0.6);
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.navbar {
  height: 80px;
  width: 100%;
  position: fixed;
  top: 0;
  background-color: white;
  z-index: 1000;
  box-shadow: 0 0 7px rgb(10,10,10);
}

.container {
  margin: 0 auto;
  max-width: 1300px;
  padding: 0 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}

.menuItemContainer {
  display: flex;
}

.menuItemContainer:hover {
  cursor: pointer;
}

.logo {
  width: 250px;
}

.menuIcon {
  width: 30px;
  margin-right: 10px;
}

.nav-elements ul {
  display: flex;
  justify-content: space-between;
  align-items: center;
  list-style-type: none;
}

.nav-elements ul li:not(:last-child) {
  margin-right: 60px;
}

.link{
  font-size: 22px;
  font-weight: 400;
  color: #2f234f;
  text-decoration: none;
}

.buttonAddToCourseMenu {
  color: white;
  font-size: 16px;
  font-family: 'Exo', sans-serif;
  width: 200px;
  padding: 10px;
  background: #005EDD;
  border: 1px solid #005EDD;
  border-radius: 30px;
}

.buttonAddToCourseMenu:hover {
  cursor: pointer;
}

.menu-icon {
  display: none;
}

.signInRight {
  display: block;
}

.iconsMenu {
  display: none;
}

@media (max-width: 1200px) {
  .menu-icon {
    display: block;
    cursor: pointer;
    width: 30px;
    height: 30px;
    margin-top: 10px;
    z-index: 1000;
  }

  .signInRight {
    display: none;
  }

  .iconsMenu {
    margin-top: 20%;
    display: block;
  }

  .iconMenu {
    width: 50px;
    height: 50px;
    margin: 5px;
  }

  .nav-elements {
    position: absolute;
    right: 0;
    top: 0;
    background-color: #ffffff;
    width: 0px;
    margin-top: 10px;
    height: 100vh;
    transition: all 0.3s ease-in;
    overflow: hidden;
  }

  .menuItemContainer {
    justify-content: center;
  }

  .nav-elements.active {
    width: 80%;
  }

  .nav-elements ul {
    display: flex;
    flex-direction: column;
    height: 60%;
    justify-content: space-evenly;
  }

  .nav-elements ul li {
    margin-right: unset;
    margin-top: 22px;
    height: 25px;
  }
}

.menu-button-container {
  display: none;
  height: 100%;
  width: 30px;
  cursor: pointer;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#menu-toggle {
  display: none;
}

.menu-button,
.menu-button::before,
.menu-button::after {
  display: block;
  background-color: #000000;
  position: absolute;
  height: 4px;
  width: 30px;
  transition: transform 400ms cubic-bezier(0.23, 1, 0.32, 1);
  border-radius: 2px;
}

.menu-button::before {
  content: '';
  margin-top: -8px;
}

.menu-button::after {
  content: '';
  margin-top: 8px;
}

#menu-toggle:checked + .menu-button-container .menu-button::before {
  margin-top: 0px;
  transform: rotate(405deg);
}

#menu-toggle:checked + .menu-button-container .menu-button {
  background: rgba(255, 255, 255, 0);
}

#menu-toggle:checked + .menu-button-container .menu-button::after {
  margin-top: 0px;
  transform: rotate(-405deg);
}