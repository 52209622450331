.text {
  margin: 0px;
  background-clip: text;
  -webkit-background-clip: text;
  text-transform: uppercase;
  color: black;
  font-family: 'Exo', sans-serif;
}

.title {
  font-size: 70px;
}

.subtitle {
  font-size: 30px;
}

.featuresContainer {
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
  justify-content: space-around;
}

.feature {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 280px;
  padding: 10px;
}

.featureTitle {
  margin-bottom: 0;
}

.featureImg {
  max-width: 150px;
}

@media (max-width: 700px) {
  #downTitleText {
    margin-bottom: 15%;
  }
}
